import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';


const initSplide = () => {
    const splide_container = document.querySelector('.splide');
    if(!splide_container) return;

    const splide_options = {
        type: 'loop',
        perPage: 1,
        rewind: true,
        arrows: false,
        autoplay: true,
        interval: 4500,
        autoHeight: true,
        pauseOnFocus: false
    }

    new Splide( splide_container , splide_options).mount(); 
}

const getYtIframeNode = (video_src, height) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute('src', video_src);
    iframe.setAttribute('height', height);
    iframe.setAttribute('width', "100%");
    iframe.setAttribute('title', "YouTube video player");
    iframe.setAttribute('frameborder', "0");
    iframe.setAttribute('allow', "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
    iframe.setAttribute('allowfullscreen', "");
    iframe.style.opacity = 0;
    return iframe;
}

const handle_injecting_yt_iframe = () => {
    const video_injector = document.querySelector('.inject-video-onclick');
    if(!video_injector) return;
    
    const inject_provider = video_injector.querySelector('.video-inject-invoker');
    if(!inject_provider) return;

    const video_src = inject_provider.dataset.ytEmbedSrc;
    const height = inject_provider.dataset.height || 350;

    if(!video_src) return;

    const video_url = new URL(video_src);
    video_url.searchParams.append('autoplay', 1);

    inject_provider.addEventListener('click', (event) => {
        inject_provider.classList.add('loading');
        const iframe = getYtIframeNode(String(video_url), height);
        iframe.onload = () => {
            inject_provider.classList.add('hidden');
            inject_provider.classList.remove('loading');
            iframe.style.opacity = 1;
        };
        video_injector.appendChild(iframe);
    })
}

initSplide();
handle_injecting_yt_iframe();